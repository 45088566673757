import React from "react";
import { Box, Paragraph } from "grommet";
import styled from "styled-components";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import { FadeInLeft, HeroFadeInUp } from "../elements/Animations";
import { Container, Section } from "../elements/Layout";
import { LinkButton } from "../elements/Button";
import HeroBg from "../images/hero.jpg";
import { Title } from "../elements/Text";
import theme from "../../config/theme";
import { ImageBox } from "../elements/ImageBox";
import { services } from "../data/posts";
import ServiceCard from "../components/ServiceCard";
import ProjectGallery from "../components/ProjectGallery";
import ContactSection from "../components/ContactSection";
import SEO from "../components/SEO";
import { ABOUT_PAGE, SERVICES_PAGE } from "../../config/routes";

const HomePage = styled.div`
  .title {
    align-self: center;
  }

  .responsive-justify {
    justify-content: center;
  }

  .d-none {
    display: none;
  }

  .slider-container {
    padding: 0;
  }

  .contact,
  .projects,
  .services {
    padding-top: 120px;
    overflow: hidden;
  }

  .services {
    overflow: hidden;
  }

  .gap {
    width: 40px;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .responsive-justify {
      justify-content: flex-start;
    }

    .title {
      align-self: flex-start;
    }

    .contact,
    .projects,
    .services {
      padding-top: 250px;
    }

    .d-lg-block {
      display: flex;
    }

    .d-lg-none {
      display: none;
    }
  }
`;

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: 600px;
  margin-top: -80px;
  background: url(${HeroBg}) no-repeat center center;
  background-size: cover;
  overflow: hidden;
`;

const HeroTitle = styled(Title)`
  font-size: 45px !important;
  margin-bottom: 24px;

  & + p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  span {
    color: ${theme.colors.brand};
  }

  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 60px !important;

    & + p {
      font-size: 22px;
      margin-bottom: 40px;
    }
  }

  @media (min-width: ${theme.breakpoints.large}) {
    font-size: 80px !important;
  }
`;

const Index = ({ location }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
    ]
  };

  return (
    <HomePage>
      <SEO title="Websites & Applications" location={location} />
      <StyledSection>
        <Container>
          <HeroFadeInUp>
            <Box align="center" justify="center" basis="full">
              <HeroTitle level="1" textAlign="center">
                Creating great solutions for
                <br />
                <span>Great people</span>.
              </HeroTitle>
              <Paragraph textAlign="center" style={{ maxWidth: "490px" }}>
                We do not just create websites and applications, we create effective tools for your business.
              </Paragraph>
              <LinkButton route={SERVICES_PAGE} variant="jumbo">
                Learn more
              </LinkButton>
            </Box>
          </HeroFadeInUp>
        </Container>
      </StyledSection>

      <Section offsetTop>
        <Container>
          <Box direction="row-responsive" align="center" basis="full">
            <FadeInLeft>
              <Box>
                <Title level="2" className="title" decorator margin={{ bottom: "medium" }}>
                  About Us
                </Title>

                <Box className="d-lg-none image-wrapper">
                  <StaticImage src="../images/about.jpg" alt="about us" placeholder="none" quality={90} />
                </Box>

                <Paragraph margin={{ vertical: "large" }}>
                  We are a team of young and creative people who like clean code, simple and user-friendly design, and
                  effective solutions for every kind of problem. Started as a hobby a few years ago, we are now helping
                  lots of people to make their businesses more visible on the Internet. Our aim is to provide the best
                  possible solution for everyone. It’s doesn’t matter if you need a small website for your local firm or
                  a big complicated CRM system for a global corporation. We will do our best to suit your needs.
                </Paragraph>

                <Box direction="row" flex={false} className="responsive-justify">
                  <LinkButton route={ABOUT_PAGE}>See more</LinkButton>
                </Box>
              </Box>
            </FadeInLeft>

            <div className="gap d-none d-lg-block" />

            <ImageBox className="d-lg-block d-none" align="end" maxWidth="585px">
              <StaticImage src="../images/about.jpg" alt="about us" placeholder="none" quality={90} />
            </ImageBox>
          </Box>
        </Container>
      </Section>

      <Section className="services">
        <Container>
          <Box direction="row" basis="full" className="responsive-justify">
            <Title level="2" decorator>
              Services
            </Title>
          </Box>
        </Container>
        <Container className="slider-container">
          <Box align="center" justify="center">
            <Box>
              <Slider {...settings}>
                {services.map(service => (
                  <ServiceCard key={service.title} name={service.key} {...service} />
                ))}
              </Slider>
            </Box>
          </Box>
        </Container>
      </Section>

      <Section className="projects">
        <Container>
          <Box direction="column" gap="medium" basis="full">
            <Box>
              <Title level="2" className="title" decorator margin={{ bottom: "medium" }}>
                Projects
              </Title>
            </Box>
            <ProjectGallery />
          </Box>
        </Container>
      </Section>

      <Section className="contact">
        <Container>
          <Box basis="full">
            <Title level="2" className="title" decorator margin={{ bottom: "large" }}>
              Contact
            </Title>
          </Box>
        </Container>

        <ContactSection />
      </Section>
    </HomePage>
  );
};

export default Index;
