import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { projects } from "../data/posts";
import { LinkButton } from "../elements/Button";
import theme from "../../config/theme";
import DIContainer from "../core/Container";

const StyledProjectGallery = styled.div`
  display: flex;
  flex-direction: column;

  .gap {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }

  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 450px;
  }

  .categories {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 10px;

    span {
      margin-bottom: 10px;
      margin-right: 15px;
      font-weight: bold;
      min-width: 100px;
    }
  }

  .excerpt {
    margin-bottom: 30px;
    font-size: 16px;
    max-width: 650px;
  }

  .title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .see-more {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;

    .item {
      position: relative;
      width: 50%;
      padding: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: border-color 0.25s ease-in-out;
        border: 1px solid transparent;
      }

      .overlay {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        top: 6px;
        bottom: 6px;
        left: 6px;
        right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: ${theme.colors.body};
        font-size: 20px;
        font-weight: bold;
        background: rgba(63, 65, 89, 0.9);
        transition: all 0.25s ease-in-out;

        span {
          opacity: 0;
          margin-top: 6px;
          transition: all 0.25s ease-in-out;
        }
      }

      &:hover,
      &:focus {
        img {
          border: 1px solid ${theme.colors.brand};
        }
      }

      &.active {
        img {
          border: 1px solid transparent;
        }

        .overlay {
          opacity: 1;

          span {
            margin-top: 0;
            opacity: 1;
          }
        }
      }
    }
  }

  @media (min-width: ${theme.breakpoints.large}) {
    flex-direction: row;

    .categories {
      flex-direction: row;

      span {
        margin-bottom: 0;
      }
    }

    .grid {
      max-width: 500px;
      margin-left: -10px;
      margin-right: -10px;

      .item {
        padding: 9px;
        min-width: 232px;
        min-height: 207px;

        .overlay {
          top: 10px;
          bottom: 10px;
          left: 10px;
          right: 10px;
        }
      }
    }

    .see-more {
      justify-content: flex-start;
    }
  }
`;

const latestProjects = projects.slice(0, 4);

const ProjectGallery = () => {
  const container = DIContainer.getInstance();
  const router = container.getRouter();
  const [currentProject, setCurrentProject] = useState(latestProjects[0]);
  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(selectNextProject, 5000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  function selectNextProject() {
    setCurrentProject(_currentProject => {
      const currentIndex = latestProjects.indexOf(_currentProject);
      let nextIndex = currentIndex + 1;

      if (nextIndex > latestProjects.length - 1) {
        nextIndex = 0;
      }

      return latestProjects[nextIndex];
    });
  }

  function onSelectProject(project) {
    setCurrentProject(project);
    clearInterval(interval.current);
  }

  return (
    <StyledProjectGallery
      onMouseLeave={() => {
        clearInterval(interval.current);
        interval.current = setInterval(selectNextProject, 5000);
      }}
    >
      <div className="grid">
        {latestProjects.map(project => (
          <div
            key={project.title}
            className={`${project === currentProject ? "active " : ""}item`}
            onClick={() => onSelectProject(project)}
          >
            <img src={project.thumbnail} alt={project.title} />
            <div className="overlay">
              <span>{project.title}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="gap" />
      <div
        className="detail"
        onClick={() => {
          clearInterval(interval.current);
        }}
      >
        <h6 className="title">{currentProject.title}</h6>
        <div className="excerpt">{currentProject.excerpt}</div>
        <div className="categories">
          <span>Categories:</span>
          {currentProject.categories.join(", ")}
        </div>
        <div className="categories">
          <span>Tags:</span>
          {currentProject.tags.join(", ")}
        </div>
        <div className="see-more">
          <LinkButton route={currentProject.route}>See more</LinkButton>
        </div>
      </div>
    </StyledProjectGallery>
  );
};

export default ProjectGallery;
