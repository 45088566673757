import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Text } from "grommet";
import { Title } from "../elements/Text";
import { LinkButton } from "../elements/Button";
import theme from "../../config/theme";
import { SERVICES_PAGE } from "../../config/routes";

const StyledImage = styled.img`
  height: 100%;
  object-fit: cover;
`;

const StyledServiceCard = styled(Box)`
  padding: 30px 20px;

  .card-content {
    box-shadow: 0 4px 13px 3px rgb(0 0 0 / 40%);
  }

  .actions {
    justify-content: center;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    padding: 30px 15px;

    .actions {
      justify-content: flex-end;
    }
  }
`;

const ServiceCard = ({ name, title, image, content }) => (
  <StyledServiceCard key={title} align="center">
    <Box align="center" className="card-content" width="medium">
      <Box height="303px" width="full">
        <StyledImage src={image} alt={title} />
      </Box>

      <Box width="full" direction="column" style={{ position: "relative" }}>
        <Box
          width="140px"
          height="24px"
          background="brand"
          align="center"
          justify="end"
          style={{ position: "absolute", left: "0", top: "-15px" }}
        >
          <Title level={6} size="22px" color="dark1">
            {title}
          </Title>
        </Box>

        <Box justify="center" background="dark2" pad="medium" gap="small">
          <Text size="small">{content}</Text>

          <Box className="actions" align="center" direction="row">
            <LinkButton hash={`#${name}`} route={SERVICES_PAGE} variant="small" color="dark1">
              See more
            </LinkButton>
          </Box>
        </Box>
      </Box>
    </Box>
  </StyledServiceCard>
);

ServiceCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string
};

export default ServiceCard;
